import api from 'api';
import {
  FormTab,
  maxLength,
  minLength,
  NumberInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resources } from 'types';
import { validator } from 'utils/validator';
import CourseEditToolbar from '../CourseEditToolbar';
import { useAppNotify } from 'hooks';
import { CourseTypeEnum } from 'api/generated';

const AdminForm = () => {
  const navigate = useNavigate();
  const { errorNotify } = useAppNotify();

  const postSave = async (data: FieldValues) => {
    try {
      await api.AdminPanelCourses.adminCoursesControllerUpdateCourse(data.id, {
        ...data,
      });
      navigate(`/${Resources.COURSES}/${data.id}/show`);
    } catch (error) {
      errorNotify(error);
    }
  };
  return (
    <TabbedForm toolbar={<CourseEditToolbar />} onSubmit={postSave}>
      <FormTab label="general">
        <SelectInput
          label="Course Type"
          source="type"
          choices={[
            { id: CourseTypeEnum.Default, name: 'Default' },
            { id: CourseTypeEnum.Certificate, name: 'Certificate' },
          ]}
          validate={required()}
          fullWidth
        />
        <TextInput
          validate={[maxLength(500), minLength(2), validator.textRequired()]}
          source="name"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={[
            maxLength(1000),
            minLength(2),
            validator.text,
            validator.textRequired(),
          ]}
          source="description"
          fullWidth
          multiline
          isRequired
        />
        <NumberInput
          validate={required()}
          source="assignmentsWeight"
          fullWidth
        />
        <NumberInput
          validate={required()}
          source="flashcardsWeight"
          fullWidth
        />
        <NumberInput
          validate={required()}
          source="testTopicsWeight"
          fullWidth
        />
        <TextInput
          validate={[maxLength(15), minLength(2), validator.textRequired()]}
          source="domainButtonName"
          label="Domain button name"
          fullWidth
          isRequired
        />
        <TextInput
          validate={[maxLength(15), minLength(2), validator.textRequired()]}
          source="chapterButtonName"
          label="Chapter button name"
          fullWidth
          isRequired
        />
        <TextInput
          validate={[maxLength(15), minLength(2), validator.textRequired()]}
          source="domainStatsButtonName"
          label="Stats domain button name"
          fullWidth
          isRequired
        />
        <TextInput
          validate={[maxLength(15), minLength(2), validator.textRequired()]}
          source="chapterStatsButtonName"
          label="Stats chapter button name"
          fullWidth
          isRequired
        />
        <NumberInput source="activeCampaignListId" fullWidth multiline />
        <SelectInput
          label="Study this category"
          source="leastKnownCategory"
          choices={[
            { id: 'chapter', name: 'Chapter' },
            { id: 'domain', name: 'Domain' },
          ]}
          validate={required()}
          fullWidth
        />
      </FormTab>
    </TabbedForm>
  );
};

export default AdminForm;
