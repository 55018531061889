import {
  CouponAllowedPeriodsEnum,
  CouponDiscountTypeEnum,
  CouponResponseDtoSubscriptionDurationTypeEnum,
  SubscribeDtoTypeEnum,
} from 'api/generated';

export const allowedSubscriptionPlansOptions = [
  { id: SubscribeDtoTypeEnum.Bronze, name: 'Bronze' },
  { id: SubscribeDtoTypeEnum.Gold, name: 'Gold' },
  { id: SubscribeDtoTypeEnum.Silver, name: 'Silver' },
];

export const allowedPeriodsOptions = [
  { id: CouponAllowedPeriodsEnum.Annual, name: 'Annual' },
  { id: CouponAllowedPeriodsEnum.Lifetime, name: 'Lifetime' },
  { id: CouponAllowedPeriodsEnum.Monthly, name: 'Monthly' },
  // TODO add CouponAllowedPeriodsEnum
  { id: CouponAllowedPeriodsEnum.InstallmentFirstMonth, name: 'First Month' },
];

export const discountTypeOptions = [
  { id: CouponDiscountTypeEnum.Amount, name: 'Amount - $' },
  {
    id: CouponDiscountTypeEnum.Percentage,
    name: 'Percentage - %',
  },
];

export const limitUsesOptions = [
  { id: 'no_limit', name: 'No limit' },
  { id: 'limit', name: 'Limit' },
];

export const expireDateOptions = [
  { id: 'never', name: 'Never expires' },
  { id: 'week', name: 'Expires in one week' },
  { id: 'month', name: 'Expires in one month' },
  { id: 'custom', name: 'Expires on' },
];

export const subscriptionDurationTypeOptions = [
  {
    id: CouponResponseDtoSubscriptionDurationTypeEnum.Forever,
    name: 'Forever',
  },
  {
    id: CouponResponseDtoSubscriptionDurationTypeEnum.Once,
    name: 'Once',
  },
  {
    id: CouponResponseDtoSubscriptionDurationTypeEnum.Repeating,
    name: 'Repeating',
  },
];
