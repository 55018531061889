import { FC } from 'react';
import {
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  required,
  TextInput,
  // useRecordContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';
import { Resources } from 'types';

import { validator } from 'utils/validator';

type InputsProps = {
  type: string;
  subscriptionsTerm: string;

  isActive: boolean;
};

const Inputs: FC<InputsProps> = ({ subscriptionsTerm, type, isActive }) => {
  const { id } = useParams();
  // const record = useRecordContext();

  // console.log('record', record);
  return (
    <Root>
      <NumberInput
        label="amount"
        source={`prices.${subscriptionsTerm}.${type}.amount`}
        disabled={!isActive}
        validate={isActive ? [validator.notZero, required()] : undefined}
        fullWidth
      />
      <TextInput
        label="description"
        source={`prices.${subscriptionsTerm}.${type}.description`}
        disabled={!isActive}
        fullWidth
        multiline
      />
      <ReferenceInput
        label="Coupon"
        source={`prices.${subscriptionsTerm}.${type}.defaultCouponId`}
        disabled={false}
        queryOptions={{
          meta: {
            courseId: id,
            period: 'lifetime',
            level: type,
          },
        }}
        reference={Resources.SEARCH_COUPONS}
      >
        <AutocompleteInput
          label="Coupon"
          optionText="name"
          alwaysOn={false}
          fullWidth
        />
      </ReferenceInput>

      <ReferenceInput
        label="First Month Coupon"
        source={`prices.${subscriptionsTerm}.${type}.firstMonthDiscountCouponId`}
        disabled={false}
        queryOptions={{
          meta: {
            courseId: id,
            period: 'installment_first_month',
            level: type,
          },
        }}
        reference={Resources.SEARCH_COUPONS}
      >
        <AutocompleteInput
          label="First Month Coupon"
          optionText="name"
          alwaysOn={false}
          fullWidth
        />
      </ReferenceInput>
    </Root>
  );
};

export default Inputs;

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
`;
